<template>
  <span v-b-visible.once="visible">
    <b-spinner v-if="loading" small />
    <b-badge v-if="error" variant="danger">
      {{ error }}
    </b-badge>
    <span v-if="!loading">
      {{ name }}
    </span>
  </span>
</template>
<script>
export default {
  props: ['id', 'object'],
  data () {
    return {
      loading: true,
      error: null,
      name: null,
    };
  },
  methods: {
    visible (isVisible) {
      if (!isVisible) {
        return;
      }
      this.$http.get(`${this.object}/${this.id}`, { noLoading: true })
        .then(({ data }) => {
          this.name = data.name;
        })
        .catch((err) => {
          this.error = err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
