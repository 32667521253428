<template>
  <b-row>
    <b-col>
      <b-card title="Background jobs">
        <blueprint-data
          api-route-path="jobs"
          :where="where"
          :params="params"
          :fields="fields"
          no-new
          no-actions
        >
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Status">
              <b-form-select v-model="status">
                <option :value="null">
                  -
                </option>>
                <option value="OK">
                  Done
                </option>>
                <option value="ERROR">
                  Error
                </option>>
                <option value="CANCELED">
                  Canceled
                </option>>
                <option value="RESCHEDULED">
                  Rescheduled
                </option>>
              </b-form-select>
            </b-form-group>
          </template>
          <template v-slot:status="{ item }">
            <b-badge v-if="item.status === 'ERROR'" variant="danger">
              Error
            </b-badge>
            <b-badge v-if="item.status === 'CANCELED'" variant="warning">
              Canceled
            </b-badge>
            <b-badge v-if="item.status === 'SCHEDULED'" variant="warning">
              Scheduled
            </b-badge>
            <b-badge v-if="item.status === 'RESCHEDULED'" variant="warning">
              Rescheduled
            </b-badge>
            <b-badge v-if="item.status === 'OK'" variant="success">
              Done
            </b-badge>
            <small v-if="item.autoResceduleCount > 0">
              Auto rescheduled count: <b-badge variant="warning">{{ item.autoResceduleCount }}</b-badge>
            </small>
          </template>
          <template v-slot:output="{ item }">
            <b-badge v-if="item.output && item.output.error && item.output.message" variant="danger">
              {{ item.output.message }}
            </b-badge>
            <small v-else><code>{{ item.output }}</code></small>
          </template>
          <template v-slot:attrs="{ item }">
            <span v-if="item.attrs.deviceId">
              Device:
              <strong><ObjectName :id="item.attrs.deviceId" object="inventory/devices" /></strong><br>
              <small>{{ item.attrs.deviceId }}</small>
              <br><br>
            </span>
            <span v-if="item.attrs.unitId">
              Unit:
              <strong><ObjectName :id="item.attrs.unitId" object="inventory/units" /></strong><br>
              <small>{{ item.attrs.unitId }}</small>
              <br><br>
            </span>
          </template>
          <template v-slot:duration="{ item }">
            <span v-if="item.duration">{{ item.duration }}s</span>
          </template>
          <template v-slot:_actions="{ item }">
            <a v-if="item.status === 'ERROR'" href="#" @click.prevent="update(item)">Reschedule</a>
            <a v-if="item.status === 'ERROR'" href="#" class="text-danger" @click.prevent="update(item, { status: 'CANCELED' })">Cancel</a>
          </template>
          <template v-slot:name="{ item }">
            <b-badge variant="dark">
              {{ item.name }}
            </b-badge>
          </template>
          <template v-slot:scheduled="{ item }">
            <span>{{ item.scheduled | moment('lll') }}</span>
          </template>
          <template v-slot:createdAt="{ item }">
            <span>{{ item.createdAt | moment('lll') }}</span>
          </template>
        </blueprint-data>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import utils from '../../../libs/utils.vue';
import ObjectName from './components/object-name.vue';

export default {
  components: {
    ObjectName
  },
  data () {
    return {
      params: {},
      where: {},
      status: null,
      fields: [
        {
          key: 'name',
          sortable: true,
          slot: true,
        },
        {
          key: 'attrs',
          sortable: false,
          slot: true,
        },
        {
          key: 'createdAt',
          label: 'Created',
          sortable: true,
          slot: true,
        },
        {
          key: 'scheduled',
          sortable: true,
          slot: true,
        },
        {
          key: 'status',
          sortable: true,
          slot: true,
        },
        {
          key: 'output',
          sortable: false,
          slot: true,
        },
        {
          key: 'duration',
          sortable: false,
          slot: true,
        },
        {
          key: '_actions',
          sortable: false,
          slot: true,
        },
      ],
    };
  },
  watch: {
    status () {
      this.buildWhere();
    },
  },
  created () {
    const allowed = ['OK', 'ERROR', 'SCHEDULED', 'RESCHEDULED', 'CANCELED'];
    if (this.$route.query.status && allowed.includes(this.$route.query.status)) {
      this.status = this.$route.query.status;
      this.buildWhere();
    }
  },
  methods: {
    buildWhere () {
      this.where = {
        ...utils.parseWhereItem('status', this.status),
      };
      this.$router.push({ name: 'tasks.jobs', query: { ...this.$route.query, status: this.status } })
      // eslint-disable-next-line handle-callback-err
      .catch((err) => {
        console.warn(err);
        ///suppres deduped @see https://github.com/vuejs/vue-router/issues/2872
      });
    },
    update (item, d) {
      const data = d || {};
      const { id } = item;
      this.$http.patch(`jobs/${id}`, data)
        .then(() => {
          this.params = {
            timestamp: new Date().getTime(),
          };
        })
        .catch(this.err);
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
  },
};
</script>
